import React, { useEffect, useState } from "react";
import styled from "styled-components";


import ScrollTop from "../../components/ScrollTop";
import { useWindowScroll } from "react-use";
import Block1 from "./components/Block1";
import Block5 from "./components/Block5";
import Block2 from "./components/Block2";
import Map from "./components/Map";
import Block4 from "./components/Block4";
import Block3 from "./components/Block3";
import Block7 from "./components/Block7";
import Block6 from "./components/Block6";
import Block8 from "./components/Block8";
import Blocktk from "./components/Blocktk";
import Blockart from "./components/Blockart";
import Blockmk from "./components/Blockmk";

const Home = () => {
    const { y: pageOffset } = useWindowScroll();
    const [visible, setVisibility] = useState(false);
    useEffect(() => {
        if (pageOffset > 400) {
            setVisibility(true);
        } else {
            setVisibility(false);
        }
    }, [pageOffset]);
    return (
        <Box_home>
            <Block1/>
            <Block2/>
            <Block3/>
            <Block4 />
            <Block5 />
            <Map />
        </Box_home>
    );
};
const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    padding: 0 0 35px 0;
    background: #F9F0D4;
    @media (max-width: 770px) {
      padding:25px 0 5px 0;
    }
    ::after{
        /* position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-position: center;
        z-index: 0;
        top: 0;
        left: 0;
        background-size: contain;
        pointer-events: none;
        background-attachment: fixed; */
    };
`;
const Title_home = styled.h2`
    color: ${(props) => props.color};
    font-size: 40px;
    margin-bottom: 24px;
    font-weight: bold;
    line-height: 1.1;
`;

export default React.memo(Home);
